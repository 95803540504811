<script>
export default {
  data() {
    return {
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
  },
  methods: {
  }
};
</script>

<template>
  <div class="acitivity-item d-flex" v-if="data.episode.publishedAt && data.episode.status === 2">
    <div class="flex-shrink-0">
      <div class="avatar-xs">
        <div class="avatar-title rounded-circle bg-soft-primary text-primary"><i class="ri-lock-line"></i></div>
      </div>
    </div>
    <div class="flex-grow-1 ms-2">
      <h6 class="mb-1"> Episode closed <span class="badge bg-soft-success text-success align-middle">Posted</span></h6>
      <p class="text-muted mb-2">{{ new Date(data.episode.publishedAt).toLocaleString() }}</p>
    </div>
  </div>
</template>