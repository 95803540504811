<script>
import Control from "./_Control.vue";

export default {
  emits: ["episodePostPlay"],
  data() {
    return {
      messages: []
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
    Control,
  },
  beforeUpdate() {
    this.messages = [];
  },
  methods: {
    setMessage: function(el) {
      if(el)
        this.messages.push(el);
    },
    stopAllMessages: function(e, emit) {
      this.messages.forEach((key) => {
        if(key.data.id !== e)
          key.pause();
      });

      if(emit)
        this.$emit('episodePostPlay', e);
    },
  },
  computed: {
  }
};
</script>

<template>
  <div class="acitivity-item d-flex w-100">
    <div class="flex-shrink-0">
      <div class="avatar-xs">
        <div class="avatar-title rounded-circle bg-primary text-white"><i class="ri-music-2-line"></i></div>
      </div>
    </div>
    <div class="flex-grow-1 ms-2">
      <h6 class="mb-1">
        <a data-bs-toggle="collapse" :href="'#resource_' + data.messages[0].id" role="button" class="text-primary">
          Resource 
          <i class="ri-arrow-down-s-line"></i>
        </a>
      </h6>
      <p class="text-muted mb-2">{{ data.messages[0].resource.title }}</p>

      <b-collapse class="multi-collapse mt-2" :id="'resource_' + data.messages[0].id">
        <div class="card" v-for="m in data.messages" v-bind:key="m" :id="'postId_' + m.id">
          <div class="card-body">
            <p class="text-muted pre-line mb-3" v-if="m.resource.memo">{{ m.resource.memo }}</p>
            <Control :ref="setMessage" v-bind:data="m" v-bind:key="m.id" @episode-message-play="stopAllMessages"></Control>
          </div>
        </div>
      </b-collapse>

    </div>
  </div>
</template>
