<script>

export default {
  data() {
    return {
    }
  },
  props: {
    id: String,
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
  },
  computed: {
  }
};
</script>

<template>
  <div class="mb-4">

    <div class="row align-items-center team-row">
      <div class="col team-settings">
        <router-link :to="{ name: 'edit-episode', params: { id: this.id } }" class="btn btn-primary">
          Edit episode
        </router-link>
      </div>
    </div>

  </div>
</template>
