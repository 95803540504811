<script>
import axios from 'axios';

export default {
  data() {
    return {
      id: null,
      buttonSaveDisabled: false,
      buttonSaveText: 'Save',
      title: '',
      message: '',
      embed: '',
      previewImage: null,
      addToPodcast: true,
    }
  },
  components: {
  },
  methods: {
    modalShown(e) {
      this.id = e.target.dataset.id;
      this.loadingPost();
    },
    modalHidden() {
      this.title = '';
      this.message = '';
      this.embed = '';
      this.previewImage = null; 
      this.addToPodcast = true;
    },
    loadingPost() {
      axios.get('/api/post/simple/' + this.id, { withCredentials: true })
        .then((response) => {
          if(response.data.post.messages.length > 0) {
            var m = response.data.post.messages[0];
            this.title = m.title;
            this.message = m.memo;
            this.embed = m.urlEmbed;
            this.previewImage = m.urlImage; 
            this.addToPodcast = !m.isIgnored;
            console.log(m);
          }
        })
        .catch(function () {
        });
    },
    preventEnterKey(){
    },
    uploadImage(e){
        const image = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = e =>{
            this.previewImage = e.target.result;
        };
    },
    clearUploadImage() {
      this.previewImage = null;
    },
    sendSavePostForm() {
      //disabled...
      this.buttonCreateDisabled = true;
      this.buttonSaveText = 'Saving...';

      //api
      var data = {
        title: this.title,
        memo: this.message,
        urlEmbed: this.embed,
        urlImage: this.previewImage,
        isIgnored: !this.addToPodcast
      };
      
      var vm = this;
      axios.post('/api/post/save/' + this.id, data, {
        withCredentials: true,
        headers: {
        }
      }).then(function () {
        console.log("Post has been saved");
        vm.$router.go();
      }).catch(function () {
      });
    },
  }
};
</script>

<template>

<b-modal @shown="modalShown" @hidden="modalHidden" hide-footer :hide-header-close="true" title="Post editor" class="v-modal-custom zoomIn postEditor" size="xl">
  <form>

    <div class="row g-3">
      <div class="col-12">
        <div>
          <label for="title" class="form-label">Title</label>
          <input type="text" class="form-control" id="title" placeholder="Your title" v-model="title" v-on:keydown.enter.prevent="preventEnterKey">
        </div>
      </div>
      <div class="col-12 ">
        <div>
          <label for="message" class="form-label">Comment</label>
          <textarea id="message" class="form-control" rows="3" placeholder="Your comment" v-model="message"></textarea>
        </div>
      </div>
      <div class="col-12">
        <div>
          <label for="embed" class="form-label">Embed Url</label>
          <input type="text" class="form-control" id="embed" placeholder="Your embed Url (youtube, vimeo)" v-model="embed" v-on:keydown.enter.prevent="preventEnterKey">
        </div>
      </div>
      <div class="col-12">
        <input type="file" ref="file" accept="image/jpeg" class="d-none" @change=uploadImage>
        <div class="border-dashed rounded p-0 text-center border-1" role="button" @click="$refs.file.click()">
          <button class="btn btn-danger btn-icon waves-effect waves-light position-absolute" @click.stop="clearUploadImage" type="button" style="top:15px;right:25px;">
            <i class="ri-delete-bin-5-line"></i>
          </button>
          <img :src="previewImage" class="w-100 m-0 p-0" />
          <div class="mt-4 mb-5">
            <h5><i class="ri-upload-line"></i> upload image</h5>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-check form-switch">
          <label for="addToPodcast">Add audio to podcast</label>
          <input class="form-check-input" type="checkbox" role="switch" id="addToPodcast" v-model="addToPodcast">
        </div>
      </div>
    </div>

  </form>

  <div class="modal-footer v-modal-footer">
    <button type="button" @click="sendSavePostForm" class="btn btn-primary" v-bind:disabled="buttonSaveDisabled">{{ buttonSaveText }}</button>
    <a href="javascript:void(0);" class="btn btn-light fw-medium" data-bs-dismiss="modal">Close</a>
  </div>
</b-modal>
</template>
